"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInitialValues = void 0;

var _lodash = require("lodash");

var _commons = require("@stktk/commons");

var _finalization = require("./finalization");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var getSkuIngredientsData = function getSkuIngredientsData(ingredients) {
  return ingredients.map(function (_ref) {
    var id = _ref.id,
        ingredientAmount = _ref.ingredientAmount,
        ingredientAmountUnitId = _ref.ingredientAmountUnitId,
        ingredientId = _ref.ingredientId;
    return {
      id: id,
      ingredientAmount: ingredientAmount,
      ingredientAmountUnitId: ingredientAmountUnitId,
      ingredientId: ingredientId
    };
  });
};

var getSkuCommonValues = function getSkuCommonValues(_ref2) {
  var domainId = _ref2.domainId,
      skuType = _ref2.skuType,
      isCopy = _ref2.isCopy,
      _ref2$skuSuppliers = _ref2.skuSuppliers,
      skuSuppliers = _ref2$skuSuppliers === void 0 ? [] : _ref2$skuSuppliers,
      barcode = _ref2.barcode,
      barcodeFormat = _ref2.barcodeFormat,
      stocktakeMode = _ref2.stocktakeMode;
  var item = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var details = (0, _commons.getSkuDetails)(item, 'domain');
  return _objectSpread(_objectSpread({}, item.id && !isCopy ? {
    id: item.id
  } : {}), {}, {
    domainId: domainId,
    type: item.type || skuType,
    stocktakeMode: item.stocktakeMode || stocktakeMode || _commons.SkuStocktakeModeEnum.getDefault(),
    name: item.name || null,
    categoryId: item.categoryId || null,
    barcode: item.barcode || barcode || null,
    barcodeFormat: item.barcodeFormat || barcodeFormat || null,
    localSupplierIds: item ? (0, _lodash.uniq)(skuSuppliers.filter(function (_ref3) {
      var skuId = _ref3.skuId;
      return skuId === item.id;
    }).map(function (_ref4) {
      var supplierId = _ref4.supplierId;
      return supplierId;
    })) : [],
    details: [_objectSpread(_objectSpread({}, (0, _lodash.get)(details, 'id') && !isCopy ? {
      id: (0, _lodash.get)(details, 'id')
    } : {}), {}, {
      reportAmount: (0, _lodash.get)(details, 'reportAmount', null),
      reportUnitId: (0, _lodash.get)(details, 'reportUnitId', _commons.UnitEnum.ITEM.id),
      netPurchasePrice: (0, _lodash.get)(details, 'netPurchasePrice', null),
      netSalePrice: (0, _lodash.get)(details, 'netSalePrice', null),
      taxRate: (0, _lodash.get)(details, 'taxRate', null),
      comment: (0, _lodash.get)(details, 'comment', null),
      tags: (0, _lodash.get)(details, 'tags', []).map(function (_ref5) {
        var id = _ref5.id;
        return id;
      }),
      config: {
        expandable: (0, _lodash.get)(details, 'config.expandable', true)
      }
    })]
  }, item.type === _commons.SkuTypeEnum.COMPOUND || skuType === _commons.SkuTypeEnum.COMPOUND ? {
    ingredients: item.ingredients ? isCopy ? getSkuIngredientsData(item.ingredients).map(function (ingredient) {
      return (0, _lodash.pick)(ingredient, ['ingredientAmount', 'ingredientAmountUnitId', 'ingredientId']);
    }) : getSkuIngredientsData(item.ingredients) : []
  } : {});
};

var SchemeInitialValues = {
  'sku.quantity.create': function skuQuantityCreate(props) {
    return _objectSpread(_objectSpread({}, getSkuCommonValues(props)), {}, {
      volume: null,
      volumeUnitId: _commons.UnitEnum.MILLILITER.id,
      packageWeightGross: null,
      packageWeightGrossArray: null,
      packageWeightGrossUnitId: _commons.UnitEnum.GRAM.id
    });
  },
  'sku.quantity.update': function skuQuantityUpdate(props, item) {
    return _objectSpread(_objectSpread({}, getSkuCommonValues(props, item)), {}, {
      volume: item.volume,
      volumeUnitId: item.volumeUnitId || _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.VOLUME).id,
      packageWeightGross: item.packageWeightGross,
      packageWeightGrossArray: null,
      packageWeightGrossUnitId: item.packageWeightGrossUnitId || _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id
    });
  },
  'sku.quantity_volume.create': function skuQuantity_volumeCreate(props) {
    return _objectSpread(_objectSpread({}, getSkuCommonValues(props)), {}, {
      volume: null,
      volumeUnitId: _commons.UnitEnum.MILLILITER.id,
      packageWeightNet: null,
      packageWeightNetArray: null,
      packageWeightGross: null,
      packageWeightGrossArray: null,
      packageWeightNetUnitId: _commons.UnitEnum.GRAM.id,
      packageWeightGrossUnitId: _commons.UnitEnum.GRAM.id,
      capWeight: null,
      capWeightUnitId: _commons.UnitEnum.GRAM.id,
      density: null,
      percentage: null
    });
  },
  'sku.quantity_volume.update': function skuQuantity_volumeUpdate(props, item) {
    return _objectSpread(_objectSpread({}, getSkuCommonValues(props, item)), {}, {
      volume: item.volume,
      volumeUnitId: item.volumeUnitId || _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.VOLUME).id,
      packageWeightNet: item.packageWeightNet,
      packageWeightNetArray: null,
      packageWeightGross: item.packageWeightGross,
      packageWeightGrossArray: null,
      packageWeightNetUnitId: item.packageWeightNetUnitId || _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id,
      packageWeightGrossUnitId: item.packageWeightGrossUnitId || _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id,
      capWeight: item.capWeight,
      capWeightUnitId: item.capWeightUnitId || _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id,
      density: item.density,
      percentage: item.percentage
    });
  },
  'sku.volume.create': function skuVolumeCreate(props) {
    return _objectSpread(_objectSpread({}, getSkuCommonValues(props)), {}, {
      volume: null,
      volumeUnitId: _commons.UnitEnum.MILLILITER.id
    });
  },
  'sku.volume.update': function skuVolumeUpdate(props, item) {
    return _objectSpread(_objectSpread({}, getSkuCommonValues(props, item)), {}, {
      volume: item.volume,
      volumeUnitId: item.volumeUnitId || _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.VOLUME).id
    });
  },
  'sku.mass.create': function skuMassCreate(props) {
    return _objectSpread(_objectSpread({}, getSkuCommonValues(props)), {}, {
      packageWeightGross: null,
      packageWeightGrossArray: null,
      packageWeightGrossUnitId: _commons.UnitEnum.GRAM.id
    });
  },
  'sku.mass.update': function skuMassUpdate(props, item) {
    return _objectSpread(_objectSpread({}, getSkuCommonValues(props, item)), {}, {
      packageWeightGross: item.packageWeightGross,
      packageWeightGrossArray: null,
      packageWeightGrossUnitId: item.packageWeightGrossUnitId || _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id
    });
  },
  'sku.quantity_mass.create': function skuQuantity_massCreate(props) {
    return _objectSpread(_objectSpread({}, getSkuCommonValues(props)), {}, {
      packageWeightNet: null,
      packageWeightNetArray: null,
      packageWeightGross: null,
      packageWeightGrossArray: null,
      packageWeightNetUnitId: _commons.UnitEnum.GRAM.id,
      packageWeightGrossUnitId: _commons.UnitEnum.GRAM.id,
      capWeight: null,
      capWeightUnitId: _commons.UnitEnum.GRAM.id
    });
  },
  'sku.quantity_mass.update': function skuQuantity_massUpdate(props, item) {
    return _objectSpread(_objectSpread({}, getSkuCommonValues(props, item)), {}, {
      packageWeightNet: item.packageWeightNet,
      packageWeightNetArray: null,
      packageWeightGross: item.packageWeightGross,
      packageWeightGrossArray: null,
      packageWeightNetUnitId: item.packageWeightNetUnitId || _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id,
      packageWeightGrossUnitId: item.packageWeightGrossUnitId || _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id,
      capWeight: item.capWeight,
      capWeightUnitId: item.capWeightUnitId || _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id
    });
  },
  'supplier.create': function supplierCreate(props) {
    return {
      domainId: props.domainId,
      deliveryDays: [],
      deliveryTime: null,
      minimumOrderValue: null,
      name: null,
      email: null,
      country: null,
      city: null,
      address: null,
      zipcode: null,
      phone: null,
      fax: null
    };
  },
  'supplier.update': function supplierUpdate(props, item) {
    return {
      id: item.id,
      domainId: props.domainId,
      deliveryDays: item.deliveryDays || [],
      deliveryTime: item.deliveryTime,
      minimumOrderValue: item.minimumOrderValue,
      name: item.name,
      email: item.email,
      country: item.country,
      city: item.city,
      address: item.address,
      zipcode: item.zipcode,
      phone: item.phone,
      fax: item.fax
    };
  },
  'property.restaurant.create': function propertyRestaurantCreate(props) {
    return {
      restaurantId: props.restaurantId,
      settings: {}
    };
  },
  'property.restaurant.update': function propertyRestaurantUpdate(props, item) {
    return {
      id: item.id,
      restaurantId: props.restaurantId,
      settings: item.settings
    };
  },
  'property.domain.create': function propertyDomainCreate(props) {
    return {
      domainId: props.domainId,
      settings: _commons.defaultDomainSettings
    };
  },
  'property.domain.update': function propertyDomainUpdate(props, item) {
    return {
      id: item.id,
      domainId: props.domainId,
      settings: _objectSpread(_objectSpread({}, _commons.defaultDomainSettings), item.settings)
    };
  },
  'document.create': function documentCreate(props) {
    return {
      recordsetId: null,
      domainId: props.domainId,
      restaurantId: props.restaurantId,
      type: null,
      comment: '',
      status: _commons.DocumentStatusEnum.UPLOADED,
      properties: {
        parashift: {
          process: false
        },
        fakir: {
          discriminator: null,
          invoiceEntryDate: null,
          isCorrectiveInvoice: false,
          correctiveInvoiceNo: null,
          saleDate: null
        }
      }
    };
  },
  'document.update': function documentUpdate(props, item) {
    return {
      id: item.id,
      domainId: props.domainId,
      restaurantId: props.restaurantId,
      comment: item.comment,
      recordsetId: item.recordsetId,
      status: item.status,
      properties: item.properties,
      number: item.number,
      manualEntryResult: _objectSpread(_objectSpread({}, (0, _lodash.get)(item, '_ocrData.included', {})), (0, _lodash.get)(item, 'manualEntryResult', {}))
    };
  },
  'report.create': function reportCreate(props) {
    return {
      restaurantId: props.restaurantId,
      stockcheck1Id: null,
      stockcheck2Id: null,
      comment: ''
    };
  },
  'storageTransferGroup.create': function storageTransferGroupCreate(props) {
    return {
      restaurantId: props.restaurantId,
      rangeFromDate: null,
      rangeToDate: null,
      comment: '',
      status: _commons.StorageTransferGroupStatusEnum.DEFAULT
    };
  },
  'storageTransferGroup.update': function storageTransferGroupUpdate(props, item) {
    return {
      id: item.id,
      restaurantId: props.restaurantId,
      comment: item.comment,
      status: item.status
    };
  },
  'storageTransfer.create': function storageTransferCreate(props) {
    return {
      domainId: props.domainId,
      sourceBarId: null,
      targetBarId: null,
      sourceRestaurantId: null,
      targetRestaurantId: null
    };
  },
  'storage.create': function storageCreate(props) {
    return {
      restaurantId: props.restaurantId,
      virtualGenerationRule: _commons.StorageStandardGenerationRuleEnum.EMPTY,
      status: props.storageStandards.length ? _commons.StorageStatusEnum.INACTIVE : _commons.StorageStatusEnum.ACTIVE,
      comment: ''
    };
  },
  'storage.update': function storageUpdate(props, item) {
    return {
      id: item.id,
      restaurantId: props.restaurantId,
      comment: item.comment
    };
  },
  'stockcheck.create': function stockcheckCreate(props) {
    return {
      comment: '',
      virtualPersonnel: [window.user.id],
      restaurantId: props.restaurantId
    };
  },
  'stockcheck.update': function stockcheckUpdate(props, item) {
    return {
      id: item.id,
      restaurantId: props.restaurantId,
      comment: item.comment
    };
  },
  'sale.create': function saleCreate(props) {
    return {
      barId: null,
      restaurantId: props.restaurantId,
      status: _commons.SaleStatusEnum.DEFAULT,
      rangeFromDate: null,
      rangeToDate: null,
      virtualGenerationRule: _commons.SaleGenerationRuleEnum.EMPTY,
      comment: ''
    };
  },
  'sale.update': function saleUpdate(props, item) {
    return {
      id: item.id,
      restaurantId: props.restaurantId,
      comment: item.comment
    };
  },
  'order.create': function orderCreate(props) {
    return {
      barId: props.barId || null,
      orderGroupId: props.orderGroupId || null,
      domainId: props.domainId,
      restaurantId: props.restaurantId,
      //virtualGenerationRule: OrderGenerationRuleEnum.EMPTY,
      supplierId: props.supplierId || '',
      comment: props.comment || '',
      status: _commons.OrderStatusEnum.CREATED,
      placedDate: null,
      shippedDate: null,
      receivedDate: null,
      finishedDate: null
    };
  },
  'order.update': function orderUpdate(props, item) {
    return {
      id: item.id,
      barId: item.barId,
      orderGroupId: item.orderGroupId,
      restaurantId: props.restaurantId,
      comment: item.comment,
      status: item.status,
      placedDate: item.placedDate,
      shippedDate: item.shippedDate,
      receivedDate: item.receivedDate,
      finishedDate: item.finishedDate
    };
  },
  'orderGroup.create': function orderGroupCreate(props) {
    return {
      restaurantId: props.restaurantId,
      virtualGenerationRule: _commons.OrderGroupGenerationRuleEnum.EMPTY,
      comment: ''
    };
  },
  'orderGroup.update': function orderGroupUpdate(props, item) {
    return {
      id: item.id,
      restaurantId: props.restaurantId,
      comment: item.comment
    };
  },
  'tag.create': function tagCreate(props) {
    return {
      domainId: props.domainId,
      name: null,
      context: _commons.TagContextEnum.SKU,
      rgbColor: null
    };
  },
  'tag.update': function tagUpdate(props, item) {
    return {
      id: item.id,
      domainId: props.domainId,
      name: item.name,
      context: item.context,
      rgbColor: item.rgbColor
    };
  },
  'category.create': function categoryCreate(props) {
    return {
      domainId: props.domainId,
      name: null,
      parentId: null,
      rgbColor: null
    };
  },
  'category.update': function categoryUpdate(props, item) {
    return {
      id: item.id,
      domainId: props.domainId,
      name: item.name,
      parentId: item.parentId,
      rgbColor: item.rgbColor
    };
  },
  'member.create': function memberCreate(props) {
    return {
      restaurantId: props.restaurantId,
      domainId: props.domainId,
      position: null,
      firstName: '',
      lastName: '',
      email: ''
    };
  },
  'member.update': function memberUpdate(props, item) {
    return {
      userId: item.id,
      restaurantId: props.restaurantId,
      domainId: props.domainId,
      tags: (item.tags || []).map(function (_ref6) {
        var id = _ref6.id;
        return id;
      }),
      domainRole: item.domainRole
    };
  },
  'skuDetails.create': function skuDetailsCreate(props, item) {
    var sku = Array.isArray(item) ? item[0].sku : item;
    return {
      domainId: props.domainId,
      restaurantId: null,
      skuId: sku ? sku.id : null,
      reportAmount: null,
      reportUnitId: _commons.UnitEnum.ITEM.id,
      netPurchasePrice: null,
      netSalePrice: null,
      grossPurchasePrice: null,
      grossSalePrice: null,
      taxRate: null,
      taxRate2: null,
      categoryId: null,
      comment: null,
      tags: [],
      localSupplierIds: [],
      config: {
        expandable: true
      }
    };
  },
  'skuDetails.update': function skuDetailsUpdate(props, item) {
    var sku = Array.isArray(item) ? item[0].sku : item;
    return {
      id: (0, _commons.getSkuDetails)(sku, 'domain').id,
      domainId: props.domainId,
      restaurantId: null,
      skuId: sku.id,
      reportAmount: (0, _commons.getSkuDetails)(sku, 'domain').reportAmount,
      reportUnitId: (0, _commons.getSkuDetails)(sku, 'domain').reportUnitId,
      netPurchasePrice: (0, _commons.getSkuDetails)(sku, 'domain').netPurchasePrice,
      netSalePrice: (0, _commons.getSkuDetails)(sku, 'domain').netSalePrice,
      grossPurchasePrice: (0, _commons.getSkuDetails)(sku, 'domain').grossPurchasePrice,
      grossSalePrice: (0, _commons.getSkuDetails)(sku, 'domain').grossSalePrice,
      taxRate: (0, _commons.getSkuDetails)(sku, 'domain').taxRate,
      taxRate2: (0, _commons.getSkuDetails)(sku, 'domain').taxRate2,
      categoryId: (0, _commons.getSkuDetails)(sku, 'domain').categoryId,
      config: (0, _commons.getSkuDetails)(sku, 'domain').config,
      comment: (0, _commons.getSkuDetails)(sku, 'domain').comment || null,
      tags: ((0, _commons.getSkuDetails)(sku, 'domain').tags || []).map(function (_ref7) {
        var id = _ref7.id;
        return id;
      }),
      localSupplierIds: item ? (0, _lodash.uniq)((props.skuSuppliers || []).filter(function (_ref8) {
        var skuId = _ref8.skuId;
        return skuId === sku.id;
      }).map(function (_ref9) {
        var supplierId = _ref9.supplierId;
        return supplierId;
      })) : []
    };
  },
  'lossGroup.create': function lossGroupCreate(props) {
    return {
      restaurantId: props.restaurantId,
      rangeFromDate: null,
      rangeToDate: null,
      comment: '',
      status: _commons.LossGroupStatusEnum.DEFAULT
    };
  },
  'lossGroup.update': function lossGroupUpdate(props, item) {
    return {
      id: item.id,
      restaurantId: props.restaurantId,
      // rangeFromDate: item.rangeFromDate,
      // rangeToDate: item.rangeToDate,
      comment: item.comment // ,status: item.status

    };
  },
  'productionGroup.create': function productionGroupCreate(props) {
    return {
      restaurantId: props.restaurantId,
      rangeFromDate: null,
      rangeToDate: null,
      comment: '',
      status: _commons.ProductionGroupStatusEnum.DEFAULT
    };
  },
  'productionGroup.update': function productionGroupUpdate(props, item) {
    return {
      id: item.id,
      restaurantId: props.restaurantId,
      // rangeFromDate: item.rangeFromDate,
      // rangeToDate: item.rangeToDate,
      comment: item.comment // ,status: item.status

    };
  },
  'lossGroup.finalize': function lossGroupFinalize(props) {
    var netGroupPurchaseValue = (0, _finalization.getNetGroupPurchaseValue)(props.recordsetType);
    var netGroupSaleValue = (0, _finalization.getNetGroupSaleValue)(props.recordsetType);
    return {
      id: props.lossGroupId,
      restaurantId: props.restaurantId,
      payload: props.skus.map(function (sku) {
        var _ref10;

        return _ref10 = {
          skuId: sku.id
        }, _defineProperty(_ref10, netGroupPurchaseValue, sku.value[netGroupPurchaseValue] || null), _defineProperty(_ref10, netGroupSaleValue, sku.value[netGroupSaleValue] || null), _ref10;
      })
    };
  },
  'stockcheck.finalize': function stockcheckFinalize(props) {
    var netGroupPurchaseValue = (0, _finalization.getNetGroupPurchaseValue)(props.recordsetType);
    var netGroupSaleValue = (0, _finalization.getNetGroupSaleValue)(props.recordsetType);
    return {
      id: props.stockcheckId,
      restaurantId: props.restaurantId,
      payload: props.skus.map(function (sku) {
        var _ref11;

        return _ref11 = {
          skuId: sku.id
        }, _defineProperty(_ref11, netGroupPurchaseValue, sku.value[netGroupPurchaseValue] || null), _defineProperty(_ref11, netGroupSaleValue, sku.value[netGroupSaleValue] || null), _ref11;
      })
    };
  },
  'bar.create': function barCreate(props) {
    return {
      restaurantId: props.restaurantId,
      name: null,
      parentId: null,
      sort: 0
    };
  },
  'bar.update': function barUpdate(props, item) {
    return {
      id: item.id,
      restaurantId: props.restaurantId,
      parentId: item.parentId,
      name: item.name,
      sort: item.sort
    };
  },
  'restaurant.create': function restaurantCreate(props) {
    return {
      domainId: props.domainId,
      name: null,
      type: null,
      country: null,
      city: null,
      address: null,
      zipcode: null,
      email: null,
      phone: null,
      fax: null
    };
  },
  'restaurant.update': function restaurantUpdate(props, item) {
    return {
      domainId: item.domainId,
      id: item.id,
      name: item.name,
      type: item.type,
      country: item.country,
      city: item.city,
      address: item.address,
      zipcode: item.zipcode,
      email: item.email,
      phone: item.phone,
      fax: item.fax
    };
  },
  'packageClosure.create': function packageClosureCreate(props) {
    return {
      domainId: props.domainId,
      name: null,
      weight: null,
      weightUnitId: _commons.UnitEnum.GRAM.id
    };
  },
  'packageClosure.update': function packageClosureUpdate(props, item) {
    return {
      id: item.id,
      domainId: props.domainId,
      name: item.name,
      weight: item.weight,
      weightUnitId: item.weightUnitId
    };
  },
  'integrationEntry.create': function integrationEntryCreate(props) {
    return {
      domainId: props.domainId,
      restaurantId: props.restaurantId,
      integrationInstanceId: (0, _lodash.get)(props, 'integrationInstance.id', null),
      integrationInstanceContextId: null,
      skuId: null,
      properties: {
        name: '',
        amount: null,
        amountUnitId: null,
        exclude: false
      },
      key: null
    };
  },
  'integrationEntry.update': function integrationEntryUpdate(props, item) {
    return {
      id: item.id,
      domainId: props.domainId,
      restaurantId: props.restaurantId,
      integrationInstanceId: item.integrationInstanceId,
      integrationInstanceContextId: item.integrationInstanceContextId,
      skuId: item.skuId,
      properties: item.properties,
      key: item.key,
      localSku: item
    };
  }
};

var getInitialValues = function getInitialValues(scheme, props, item) {
  if (!SchemeInitialValues[scheme]) throw new Error("Did not found sheme: \"".concat(scheme, "\" in @stktk/logic/utils/form."));
  return SchemeInitialValues[scheme](props, item);
};

exports.getInitialValues = getInitialValues;