import React, { useEffect } from 'react';
import {
  InputGroup,
  InputGroupAddon,
  Button
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useField } from 'formik';
import { __ } from '@stktk/locales';
import { domainIdSelector, restaurantIdSelector, updateSkuDetails, createSkuDetails } from '@stktk/redux';
import { getSkuDetails } from '@stktk/commons';
import { useSettings } from '@stktk/logic/hooks/settings';
import { toast } from '$providers/ToastProvider';
import { globalHandleError } from '$utils/error';
import FormInputLock from '$ui/form/FormInputLock';
import { Icon } from '$ui';

const FormRecordPriceInputLock = ({ name, updateItemDetails, sku, skuDetailsName, buttonLabel = __('button_update_sku_price'), formLocks, hideLockButton, ...props }) => {
  const domainId = useSelector(domainIdSelector);
  const restaurantId = useSelector(restaurantIdSelector);
  const [{ value }, , { setValue }] = useField(name);
  const settings = useSettings();
  const dispatch = useDispatch();
  const [locks, toggleLock] = formLocks;

  useEffect(() => {
    console.log('sku', sku);
    if (sku && skuDetailsName && !value) {
      const skuDetailsValue = getSkuDetails(sku, 'domain')?.[skuDetailsName];
      if (skuDetailsValue)setValue(skuDetailsValue);
    }
  }, []);

  const handleSkuDetailsUpsert = () => {
    let { id, tags = [] } = (sku.details && sku.details.length ? getSkuDetails(sku, 'domain') : {});
    let data = { [skuDetailsName]: value, domainId, skuId: sku.id, tags: tags.map(tag => tag.id), ...(id ? { id } : { restaurantId: settings.skuDetailsLevel === 'restaurant' ? restaurantId : null }) };
    let action = id ? updateSkuDetails(data) : createSkuDetails(data);
    return dispatch(action)
      .then(skuDetials => {
        console.log('skuDetials', skuDetials);
        updateItemDetails(skuDetials);
        toast(__('alert_saved'), __('alert_sku_price_todo'), 'success');
      })
      .catch(globalHandleError);
  };

  return (
    <InputGroup>
      <FormInputLock hideLockButton name={name} formLocks={formLocks} {...props} />
      <InputGroupAddon addonType="append">
        <Button onClick={handleSkuDetailsUpsert}>
          {buttonLabel}
        </Button>
        {!hideLockButton ? (
          <Button color={locks[name] ? 'primary' : 'light'} onClick={() => toggleLock(name)}>
            <Icon icon={locks[name] ? 'lock' : 'unlock'} size="sm" />
          </Button>
        ) : null}
      </InputGroupAddon>
    </InputGroup>
  );
};

export default FormRecordPriceInputLock;