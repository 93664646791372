import React, { Component } from 'react';
import { connect } from 'formik';
import { isEqual, isFinite, toFinite, get } from 'lodash';
import { FormGroup } from 'reactstrap';
import { InfoTooltip } from '$ui';
import Input from '$ui/form/controls/Input';

class FormCalcGroup extends Component {
  state = {
    focus: false,
    value: null
  }

  componentDidMount() {
    this.calculate();
  }

  componentDidUpdate() {
    if (!this.state.focus) {
      if (!isEqual(this.state.value, this.props.value))
        this.setState({ value: this.props.value });
      else this.calculate();
    }
  }

  getSku = () => {
    const { formik, isDetails } = this.props;
    return isDetails ? { details: [formik.values] } : formik.values;
  }

  calculate = () => {
    const { name, calculate, dispatchCalculations } = this.props;
    const value = calculate(this.getSku());
    if (!isEqual(this.state.value, value))
      dispatchCalculations(() => ({ [name]: value }));
  }

  handleFocus = () => this.setState({ focus: true });

  handleBlur = () => this.setState({ focus: false });

  handleChange = e => {
    const { formik, getNetSalePrice, isDetails, formLocks } = this.props;
    const [locks] = formLocks;
    const value = toFinite(e.target.value);
    const netSalePrice = getNetSalePrice(this.getSku(), value).toFixed(2);
    const saleTaxRate = get(formik.values, `${isDetails ? 'taxRate' : 'details[0]taxRate'}`);
    const grossSalePrice = saleTaxRate && netSalePrice ? netSalePrice * (1 + saleTaxRate / 100) : null;
    if (!locks[`${isDetails ? 'netSalePrice' : 'details[0]netSalePrice'}`])
      formik.setFieldValue(isDetails ? 'netSalePrice' : 'details[0]netSalePrice', toFinite(netSalePrice));
    if (!locks[`${isDetails ? 'grossSalePrice' : 'details[0]grossSalePrice'}`])
      formik.setFieldValue(isDetails ? 'grossSalePrice' : 'details[0]grossSalePrice', toFinite(grossSalePrice));
    this.setState({ value });
  }

  displayString = value =>
    value.length > 5 ? value.substring(0, 4) : value;

  display = (value, focus) =>
    value === null || !isFinite(value) ? '' : focus ? this.displayString(value.toString()) : value.toFixed(2);

  render() {
    const { label, name, info } = this.props;
    const { value, focus } = this.state;
    return (
      <FormGroup>
        {label &&
          <label>{label}</label>
        }
        {info &&
          <InfoTooltip info={info}/>
        }
        <Input
          id={`FormCalcGroup-input-${name}`}
          value={this.display(value, focus)}
          placeholder={label}
          type="number"
          step={0.01}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
        />
      </FormGroup>
    );
  }
}

export default connect(FormCalcGroup);