import React from 'react';
import {
  InputGroup,
  InputGroupAddon,
  Button
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useField } from 'formik';
import { __ } from '@stktk/locales';
import { domainIdSelector, restaurantIdSelector, updateSkuDetails, createSkuDetails } from '@stktk/redux';
import { getSkuDetails } from '@stktk/commons';
import { useSettings } from '@stktk/logic/hooks/settings';
import { toast } from '$providers/ToastProvider';
import { globalHandleError } from '$utils/error';
import FormInput from '$ui/form/FormInput';

const FormRecordPriceInput = ({ name, sku, skuDetailsName, buttonLabel = __('button_update_sku_price'), ...props }) => {
  const domainId = useSelector(domainIdSelector);
  const restaurantId = useSelector(restaurantIdSelector);
  const [{ value }] = useField(name);
  const settings = useSettings();
  const dispatch = useDispatch();

  const handleSkuDetailsUpsert = () => {
    let { id, tags = [] } = (sku.details && sku.details.length ? getSkuDetails(sku, 'domain') : {});
    let data = { [skuDetailsName]: value, domainId, skuId: sku.id, tags: tags.map(tag => tag.id), ...(id ? { id } : { restaurantId: settings.skuDetailsLevel === 'restaurant' ? restaurantId : null }) };
    let action = id ? updateSkuDetails(data) : createSkuDetails(data);
    return dispatch(action)
      .then(() => toast(__('alert_saved'), __('alert_sku_price_todo'), 'success'))
      .catch(globalHandleError);
  };

  return (
    <InputGroup>
      <FormInput name={name} {...props} />
      <InputGroupAddon addonType="append">
        <Button color="light" onClick={handleSkuDetailsUpsert}>
          {buttonLabel}
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default FormRecordPriceInput;