import React, { useEffect, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import { toNumber } from 'lodash';
import classNames from 'classnames';
import {
  InputGroup,
  InputGroupAddon,
  Button
} from 'reactstrap';
import { Icon } from '$ui';
import Input from '$ui/form/controls/Input';

const FormInputLock = ({ name, type, className, onChange, submitOnBlur, focusOnRender, formLocks, formUpdates, hideLockButton, ...props }) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField(name);
  const { submitForm, values, setFieldValue } = useFormikContext();
  const ref = useRef(null);
  const [locks, toggleLock] = formLocks;

  const getValue = (value, type) => value && value.length ? type === 'number' ? toNumber(value) : value : null;

  const handleChange = e => {
    const { value } = e.target;
    const fieldValue = getValue(value, type);
    if (onChange)
      onChange(e);
    if (formUpdates) {
      for (let formUpdate of formUpdates) {
        if (!locks[formUpdate.name]) {
          const formUpdateValue = formUpdate.calculate(value, values);
          if (formUpdateValue) setFieldValue(formUpdate.name, formUpdateValue);
        }
      }
    }
    setValue(fieldValue);
  };

  const handleBlur = () => {
    if (submitOnBlur) submitForm();
    else setTouched(true);
  };

  useEffect(() => {
    if (focusOnRender) ref.current.focus();
  }, []);

  if (hideLockButton)
    return (<Input
      ref={ref}
      data-testid={`Input-${props.name}`}
      name={name}
      type={type}
      value={value == null ? '' : value}
      className={classNames('Input', { 'is-invalid': touched && error, 'is-valid': touched && !error }, className)}
      onChange={handleChange}
      onBlur={handleBlur}
      {...props}
    />);

  return (
    <InputGroup>
      <Input
        ref={ref}
        data-testid={`Input-${props.name}`}
        name={name}
        type={type}
        value={value == null ? '' : value}
        className={classNames('Input', { 'is-invalid': touched && error, 'is-valid': touched && !error }, className)}
        onChange={handleChange}
        onBlur={handleBlur}
        {...props}
      />
      <InputGroupAddon addonType="append">
        <Button color={locks[name] ? 'primary' : 'light'} onClick={() => toggleLock(name)}>
          <Icon icon={locks[name] ? 'lock' : 'unlock'} size="sm" />
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default FormInputLock;