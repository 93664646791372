import React from 'react';
import { connect, getIn } from 'formik';
import { kebabCase } from 'lodash';
import { FormText } from 'reactstrap';
import { __validatorError } from '@stktk/locales';
import FormInput from '$ui/form/FormInput';
import FormInputLock from '$ui/form/FormInputLock';
import FormSelect from '$ui/form/FormSelect';
import FormCheckbox from '$ui/form/FormCheckbox';
import FormDate from '$ui/form/FormDate';
import FormColor from '$ui/form/FormColor';
import FormPhone from '$ui/form/FormPhone';
import FormCountry from '$ui/form/FormCountry';
import FormMarkdown from '$ui/form/FormMarkdown';
import FormRecordPriceInput from '$ui/form/FormRecordPriceInput';
import FormRecordPriceInputLock from '$ui/form/FormRecordPriceInputLock';
import FormIngredientLabel from '$ui/form/FormIngredientLabel';
import FormBarcode from '$ui/form/FormBarcode';
import FormButtonGroupSelect from '$ui/form/FormButtonGroupSelect';
import FormWeekdayPicker from '$ui/form/FormWeekdayPicker';
import { InfoTooltip } from '$ui';

export class FormGroup extends React.Component {

  getElement = () => {
    let {
      info,
      label,
      element,
      className,
      elementClassName,
      name,
      formik,
      innerRef,
      value,
      onLabelClick,
      formLocks,
      formUpdates,
      ...props
    } = this.props;
    var component = null;
    let idx = name ? `FormGroup-${element}-${kebabCase(name)}` : `FormGroup-${element}`;
    switch (element) {
      case 'input': component = <FormInput id={idx} name={name} className={elementClassName} {...props} />; break;
      case 'inputLock': component = <FormInputLock
        id={idx} name={name} className={elementClassName} formLocks={formLocks}
        formUpdates={formUpdates}
        {...props}
      />; break;
      case 'recordPriceInputLock': component = <FormRecordPriceInputLock
        id={idx} name={name} className={elementClassName} formLocks={formLocks}
        formUpdates={formUpdates} {...props}
      />; break;
      case 'recordPriceInput': component = <FormRecordPriceInput id={idx} name={name} className={elementClassName} {...props} />; break;
      case 'ingredientLabel': component = <FormIngredientLabel name={name} className={elementClassName} onLabelClick={onLabelClick} {...props} />; break;
      case 'select': component = <FormSelect id={idx} name={name} className={elementClassName} {...props} />; break;
      case 'checkbox': component = <FormCheckbox id={idx} name={name} className={elementClassName} {...props} />; break;
      case 'date': component = <FormDate id={idx} name={name} className={elementClassName} {...props} />; break;
      case 'phone': component = <FormPhone id={idx} name={name} className={elementClassName} {...props} />; break;
      case 'country': component = <FormCountry name={name} className={elementClassName} {...props} />; break;
      case 'color': component = <FormColor name={name} className={elementClassName} {...props} />; break;
      case 'barcode': component = <FormBarcode id={idx} name={name} className={elementClassName} {...props} />; break;
      case 'markdown': component = <FormMarkdown id={idx} name={name} {...props} />; break;
      case 'buttonGroup': component = <FormButtonGroupSelect id={idx} name={name} {...props} />; break;
      case 'weekday': component = <FormWeekdayPicker id={idx} name={name} {...props} />; break;
    }
    return component;
  }
  render() {
    let {
      label,
      className,
      name,
      formik,
      info,
      formText,
      formTextColor = 'info',
      type
      //formatName,
      //value,
      //...props
    } = this.props;
    const error = getIn(formik.errors, name);
    const touch = getIn(formik.touched, name);

    let valid = '';
    if (touch)
      valid = error ? ' has-error' : ' has-success';

    let combineClass = 'FormGroup form-group' + (className ? ' ' + className : '') + valid;

    if (type === 'hidden')
      return <input type="hidden" name={name} />;

    return (
      <div className={combineClass}>
        {label &&
          <label className="control-label" htmlFor={name}>{label}</label>
        }
        {info &&
          <InfoTooltip info={info}/>
        }
        {this.getElement()}
        {formText && (
          <FormText color={formTextColor}>
            {formText}
          </FormText>
        )}
        {error && touch &&
          <div className="FormGroup-error text-danger invalid-feedback d-block">{__validatorError(error, { path: label })}</div>
        }
      </div>
    );
  }

}

export default connect(FormGroup);